import { render, staticRenderFns } from "./Order.vue?vue&type=template&id=967eb7e2&scoped=true"
import script from "./Order.vue?vue&type=script&lang=js"
export * from "./Order.vue?vue&type=script&lang=js"
import style0 from "./Order.vue?vue&type=style&index=0&id=967eb7e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "967eb7e2",
  null
  
)

export default component.exports